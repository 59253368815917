

.myCalculator{
  width: 315px;
  background: #000;
  color: white;
  font-family: 'Orbitron', sans-serif;
  border-radius: 10px;
  margin: 0 auto;
}

.buttons{
  display: inline-block;
  margin: 23px 15px;
}

.btn{
  background: #323232;
  color: white;
  padding: 5%;
  border: 0px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 5px;
  font-size: 25px;
  cursor: pointer;
  outline: none;
}
.btn:focus{
  background: white;
  color: #323232;
}

.btn-calc{
  background: #FF9500;

}
.btn-action{
  background: #A6A6A6;
  color: #323232;
}

.btn-0{
  background: #323232;
  color: white;
  border: 0px;
  margin: 5px;
  padding: 5%;
  width: 130px;
  border-radius: 30px;
  height: 60px;
  font-size: 25px;
  cursor: pointer;
  outline: none;
}
.btn-0:focus{
  background: white;
  color: #323232;
}
.result{
  width: 100%;
  height: 62px;
  margin-top: 30px;
  background: #18e0c6e0;
}   

.result p{
  text-align: right;
  font-size: 26px;
  margin-bottom: 4px;
  padding-top: 14px;
  padding-right: 10px;
}